.pb_singleentry {
  section.pb-section {
    h3 {
      margin-top: 0;
      margin-bottom: 16px;
      text-transform: lowercase;

      @screen lg {
        margin-top: 64px;
      }
    }

    > a {
      @extend .underline
    }

    p {
      font-weight: 300;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px;
    }

    ul {
      margin-top: 32px;
      list-style: none; /* Remove default bullets */
      padding-left: 30px;

      li {
        margin-bottom: 32px;
        font-weight: 300;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 26px;

        &::before {
          content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
          color: $yellow; /* Change the color */
          font-weight: bold; /* If you want it to be bold */
          display: inline-block; /* Needed to add space between the bullet and the text */
          width: 2em;
          margin-left: -2em;
        }
      }
    }

    a.btn {
      margin-top: 24px;
    }
  }

  section.pb-section:first-child {
    margin-top: 48px;
    margin-bottom: 64px;
  }

  section.pb-section:nth-child(2) {
    h3 {
      margin-top: 0;
    }
  }
}

figure {
  margin-top: 32px;

  figcaption {
    margin-top: 24px;
    font-family: $copyFont;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    opacity: 0.6;
    color: $copyColor;
  }
}

.pb-section.split {
  figure {
    margin-top: 0;
  }

  h3 {
    margin-top: 0 !important;
    margin-bottom: 16px;
  }
}

#rel-entries {
  h2 {
    font-size: 38px;
    font-weight: bold;
    letter-spacing: 0.47px;
    line-height: 48px;

    text-transform: uppercase;

    @screen lg {
      margin-top: 100px;
      margin-bottom: 80px;
    }
  }
}
