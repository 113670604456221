.beer-features {
  div.circle {
    display: flex;
    margin-right: 10px;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;

    img {
      transform-origin: center;
      transform: scale(0.7);
    }

    @screen md {
      width: 50px;
      height: 50px;

      img {
        transform-origin: initial;
        transform: scale(1);
      }
    }

    img.white {
      filter: brightness(0) saturate(100%) invert(99%) sepia(95%) saturate(529%) hue-rotate(264deg) brightness(116%) contrast(100%);
    }
  }

  .beer-feat-label {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 14px;

    @screen md {
      font-size: 16px;
      letter-spacing: 0.3px;
      line-height: 20px;
    }
  }
}
