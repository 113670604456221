.glide__slide {
  /*width: 300px;
  height: 400px;*/

  @screen md {
    // margin-right: 30px !important;
  }
}

.glide-control {
  margin: 0 12px;
  cursor: pointer;
  transition: transform 0.35s $defEasing;
  transform: scale(1);
  transform-origin: center;

  &:hover {
    transform: scale(1.05);

    svg g {
      stroke: $yellow;
    }
  }

  .back {

  }

  .next {

  }
}
