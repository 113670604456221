html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "HCo Gotham SSm", sans-serif;
  font-size: 16px;
  background: #fff url("https://eichhof.ch/assets/pattern.svg");
  background-size: 121%;
  background-repeat: repeat-y;
  background-position-x: -40px;

  &.inverted {
    background: #0f181f url("https://eichhof.ch/assets/pattern.svg");
    background-size: 121%;
    background-repeat: repeat-y;
    background-position-x: -40px;

    &::after {
      content: "";
      background: rgba(15,24,31,0.8);
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
    }
  }

  &.inverted {
    background: #0f181f url("https://eichhof.ch/assets/pattern.svg");
    background-size: 121%;
    background-repeat: repeat-y;
    background-position-x: -40px;

    &::after {
      content: "";
      background: rgba(15,24,31,0.8);
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
    }
  }
}



b, strong {
  font-weight: 700;
}

#main-container {
  min-height: 70vh;

  > section {
    padding-top: 50px;
    // padding-bottom: 50px;
  }
}

section > h2 {
  color: black;
  text-align: center;
  margin-bottom: 2rem;
  border-bottom: 2px solid $yellow;
}

@font-face{
  font-family:"Novecento Slab W03 Wd Bd";
  src:url("./fonts/f88d4246-d5f4-4093-b111-62399e4e01af.woff") format("woff");
}

@font-face{
  font-family:"Novecento slab wide W05 Bold";
  src:url("./fonts/f91c31f3-a0d9-4a6f-b1a2-e196780ebf55.woff2") format("woff2");
}

@font-face{
  font-family: 'HCo Gotham SSm';
  src:url('./fonts/GothamSSm-Light_Web.woff2') format('woff2'),
  url('./fonts/GothamSSm-Light_Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face{
  font-family: 'HCo Gotham SSm';
  src:url('./fonts/GothamSSm-Medium_Web.woff2') format('woff2'),
  url('./fonts/GothamSSm-Medium_Web.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face{
  font-family: 'HCo Gotham SSm';
  src:url('./fonts/GothamSSm-Bold_Web.woff2') format('woff2'),
  url('./fonts/GothamSSm-Bold_Web.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

h1 {
  font-family: $headlineFont;
  color: $h1-color;
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;

  @screen md {
    font-size: 84px;
    line-height: 74px;
    letter-spacing: 0;
  }

  &.inverted {
    color: $h2-color;
  }
}

h2 {
  font-family: $headlineFont;
  color: $h2-color;
  font-size: 40px;
  line-height: 40px;

  @screen md {
    font-size: 64px;
    letter-spacing: 0.8px;
    line-height: 66px;
  }
}

h3 {
  font-family: $headlineFont;
  color: $h2-color;
  text-transform: uppercase;
  font-size: 32px;
  letter-spacing: 0.4px;
  line-height: 36px;
}

p {
  font-family: $copyFont;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  @screen md {
    font-size: 20px;
  }
}

body.inverted {
  #main-container {
    h1, h2, h3, p {
      color: #fff;
    }
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%; /* ratio 16x9 */
  height: 0;
  overflow: hidden;
  width: 100%;
  height: auto;
}
.embed-container iframe {

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* ratio 4x3 */
.embed-container.ratio4x3 {
  padding-bottom: 75%;
}

.abs-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 0;

  .abs-cont {
    position: absolute;
    bottom: 0;
    width: 100%;

    > div {
      height: 150px;


      @screen md {
        height: 240px;
      }
    }
  }

  @supports (-webkit-backdrop-filter: blur(1px)) {
    .abs-cont {
      > div {
        margin-top: -1px;
      }
    }
  }
}

.underline {
  display: inline;
  position: relative;
  overflow: hidden;
}
.underline:after {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0;
  width: 0;
  bottom: -5px;
  background: $yellow;
  height: 4px;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.underline:hover:after,
.underline:focus:after,
.underline:active:after {
  left: 0;
  right: auto;
  width: 100%;
}

.copyCont {
  h1 {
    color: $copyColor;
    margin-bottom: 32px;
  }
}

body.single-beer {
  .back-btn-link {
    display: block;
    font-family: $headlineFont;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 14px;
    color: #A0A3A6;
    text-transform: uppercase;
    margin-bottom: 43px;

    svg {
      width: 30px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;

      path {
        fill: #A0A3A6;
      }
    }

    @screen md {
      display: none;
    }
  }
}

body:not(.single-beer) {
  .back-btn-link {
    display: none;
  }
}

.kachel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

.collapse-content p {
  transition: all 0.3s $defEasing;
}

.read-more-indicator {
  font-size: 20px;
  font-weight: 300;
  text-decoration: underline;
  margin-top: 0.5rem;
}

.invisible {
  visibility: hidden;
}

