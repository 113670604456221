.btn {
  padding: 1rem 2rem;
  min-height: 64px;
  justify-content: center;
  align-items: center;
  font-family: $headlineFont;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 14px;
  text-align: center;
  display: flex;

  @screen md {
    display: inline-flex;
  }

  &.btn-yellow {
    text-transform: uppercase;
    transition: all 0.30s $defEasing;

    &.btn-primary {
      background: $yellow-btn;
      color: $copyColor;
      border: 2px solid $yellow-btn;

      &:hover {
        background: transparent;
        color: $yellow-btn;
        border: 2px solid $yellow-btn;
      }
    }

    &.btn-secondary {
      background: transparent;
      color: $yellow-btn;
      border: 2px solid $yellow-btn;

      &:hover {
        background: $yellow-btn;
        color: $copyColor;
        border: 2px solid $yellow-btn;
      }
    }
  }

  &.btn-black {
    text-transform: uppercase;
    display: inline-flex;
    margin-top: 32px;

    &.btn-primary {
      background: $copyColor;

      &:hover {
        background: transparent;
        color: $copyColor !important;
        border: 2px solid $copyColor;
      }
    }

    &.btn-secondary {
      background: transparent;
      color: $copyColor;
      border: 2px solid $copyColor;

      &:hover {
        background: $copyColor;
      }
    }
  }
}

a.link {
  &:after {
    content: "";
    border-bottom: 2px solid $yellow;
  }
}

/* dropdown list */
.dropdown {
  margin: 20px 0;
  width: 171px;
  z-index: 10;

  position: relative;
  perspective: 800px;
  &.active {
    .selLabel {
      &:after {
        content: '';
      }
    }
    .dropdown-list {
      li {
        &:nth-child(1) {
          transform: translateY(-100%);
        }
        &:nth-child(2) {
          transform: translateY(-200%);
        }
        &:nth-child(3) {
          transform: translateY(-300%);
        }
        &:nth-child(4) {
          transform: translateY(-400%);
        }
        &:nth-child(5) {
          transform: translateY(-500%);
        }
        &:nth-child(6) {
          transform: translateY(-600%);
        }
        &:nth-child(7) {
          transform: translateY(-700%);
        }
      }
    }
  }
  > span {
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: $copyColor;
    outline: 2px solid $copyColor;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    // line-height: 14px;
    background: $yellow;
    display: block;
    padding: 0 50px 0 45px;
    position: relative;
    z-index: 9999;
    cursor: pointer;

    transform-style: preserve-3d;
    transform-origin: center bottom;
    transition: transform 300ms;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none;
    user-select: none;

    &:after {
      content: '';
      background-repeat: no-repeat;
      background-size: 100%;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23101820' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M1 1l6 6 6-6'/%3E%3C/svg%3E");
      position: absolute;
      right: 15px;
      top: 50%;
      width: 25px;
      text-align: center;
      font-size: 12px;
      padding: 10px;
      height: 20px;
      transform: translateY(-50%);
      line-height: 24px;
    }

    &:active {
      transform: rotateX(-45deg);
      &:after {
        content: "";
        transform: scaleY(-1) translateY(50%);
      }
    }
  }

  &.lang {
    > span {
      &:after {
        top: 60%;
        width: 15px;
      }
    }
  }
}
.dropdown-list {
  position: absolute;
  top: 0;
  width: 100%;
  li {
    display: block;
    list-style: none;
    left: 0;
    opacity: 1;
    transition: transform 300ms ease;

    position: absolute;
    top: 0;
    width: 100%;
    &:nth-child(1) {
      background-color: $yellow;
      outline: 1px solid $copyColor;
      z-index: 4;
      transform: translateY(0);
    }
    &:nth-child(2) {
      background-color: $yellow;
      outline: 1px solid $copyColor;
      z-index: 3;
      transform: translateY(0);
    }
    &:nth-child(3) {
      background-color: $yellow;
      outline: 1px solid $copyColor;
      z-index: 2;
      transform: translateY(0);
    }
    &:nth-child(4) {
      background-color: $yellow;
      outline: 1px solid $copyColor;
      z-index: 1;
      transform: translateY(0);
    }
    span {
      box-shadow: 0 1px 1px rgba(0,0,0,0.2);
      -webkit-backface-visibility: hidden;
      -webkit-touch-callout: none;
      user-select: none;

      width: 100%;
      font-size: 18px;
      line-height: 60px;
      padding: 0 30px;
      display: block;
      color: #fff;
      cursor: pointer;
      letter-spacing: 2px;
    }
  }
}

.dd-mobile-pb,
.dd-mobile {
  position: fixed;
  bottom: 0;
  margin: 10px 0;
  margin-bottom: 0;
  left: 0;
  width: 100%;

  span.selLabel {
    padding-left: 15px;
    text-transform: uppercase;
  }

  .dropdown-list {
    li {
      padding: 10px;
      border-top: 2px solid $copyColor;

      &:nth-child(1) {
        background-color: $yellow;
        outline: 0;
        z-index: 4;
        transform: translateY(0);
      }
      &:nth-child(2) {
        background-color: $yellow;
        outline: 0;
        z-index: 3;
        transform: translateY(0);
      }
      &:nth-child(3) {
        background-color: $yellow;
        outline: 0;
        z-index: 2;
        transform: translateY(0);
      }
      &:nth-child(4) {
        background-color: $yellow;
        outline: 0;
        z-index: 1;
        transform: translateY(0);
      }
      &:nth-child(5) {
        background-color: $yellow;
        outline: 0;
        z-index: 1;
        transform: translateY(0);
      }
      &:nth-child(6) {
        background-color: $yellow;
        outline: 0;
        z-index: 1;
        transform: translateY(0);
      }
    }
  }
}

.dd-mobile span.selLabel {
  outline: 0;
}

.dd-mobile {
  .dropdown-list {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;

    li {
      border-top: 0;
    }
  }
}

.select-box {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  font-size: 18px;
  color: $copyColor;
  text-transform: uppercase;

  .select-box__icon {
    svg path {
      stroke: $yellow;
    }
  }

  &__current {
    position: relative;
    cursor: pointer;
    outline: none;

    &:focus {

      .select-box__value {
        p {
          color: $copyColor;
          font-weight: 700;
          text-transform: uppercase;
          border-bottom: 2px solid white;
        }
      }
      background: $yellow;

      & + .select-box__list {
        opacity: 1;

        // We have to set "animation-name: none;" to make the list visible (read below how it works)

        animation-name: none;

        .select-box__option {
          cursor: pointer;
        }
      }

      .select-box__icon {
        transform-origin: center center;
        transform: translateY(-50%) rotate(180deg);

        svg path {
          stroke: $copyColor;
        }
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 20px;
    opacity: 1;
    transition: 0.2s ease;

    svg path {
      stroke: $yellow;
    }
  }

  &__value {
    display: flex;
  }

  &__input {
    display: none;

    &:checked + .select-box__input-text {
      display: block;
    }
  }

  &__input-text {
    display: none;
    width: 100%;
    margin: 0;
    padding: 20px;
    background-color: transparent;
    color: $yellow;
    border: 2px solid $yellow;
    font-size: 14px;
    font-weight: 700;
  }

  &__list {
    position: absolute;
    width: calc(100% - 32px);
    left: 16px;
    right: 16px;
    padding: 0;
    list-style: none;
    opacity: 0;
    z-index: 2;

    // We need to use animation with delay.
    // Otherwise the click event will not have time to run on label, because this element disapears immediately when .select-box__current element loses the focus.
    // This delay will not be noticed because we set "opacity" to "0".
    // We also use "animation-fill-mode: forwards" to make the list stay hidden.

    animation-name: HideList;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: step-start;
    box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
  }

  &__option {
    display: block;
    padding: 10px 20px;
    font-weight: 700;
    background-color: $yellow;
    font-size: 14px;

    &:hover,
    &:focus {
      color: #546c84;
      background-color: #fbfbfb;
    }
  }
}

@keyframes HideList {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}
