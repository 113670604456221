.single-beer-archive {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  > a {
    padding-bottom: 1rem;
  }

  > a > img {
    transform: scale(0.26);
    transform-origin: bottom center;
    transition: all 0.65s $defEasing;
    pointer-events: none;
  }

  p.link {
    margin-top: 1rem;
    height: 0;
    opacity: 0;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 14px;
    text-transform: uppercase;
    transition: all 0.65s $defEasing;
    display: block;
  }

  img {
    transform: scale(0.26) translateY(-70px);
  }

  p.link {
    height: 34px;
    opacity: 1;
  }

  .beer-title {
    transform: translateY(0);
  }

  @screen lg {
    img {
      transform: scale(0.26) translateY(0);
    }

    p.link {
      height: 0;
      opacity: 0;
    }

    .beer-title {
      transform: translateY(0);
    }

    &:hover {
      img {
        transform: scale(0.26) translateY(-70px);
      }

      p.link {
        height: 34px;
        opacity: 1;
      }

      .beer-title {
        transform: translateY(-10px);
      }
    }
  }

  .beer-title {
    color: $copyColor;
    font-family: $headlineFont;
    text-align: center;
    max-width: 230px;
    display: flex;
    height: 3rem;
    justify-content: center;
    align-items: center;
    transition: all 0.65s $defEasing;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;

    @screen md {
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 0.4px;
      line-height: 32px;
    }
  }

  @supports (-webkit-backdrop-filter: blur(1px)) {
    .beer-title {
      display: block;
    }
  }
}

body.home {
  #main-container {
    margin-top: -50px;
  }

  .copyCont {
    h1 {
      @extend h2
    }
  }

  .hero-container {
    min-height: 98vh;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    display: flex;
    justify-content: flex-start;

    //clip-path: ellipse(200vw 112vh at -25vw -13vh);

    .btn {
      display: flex;
    }

    align-items: flex-end;
    padding-bottom: 17%;

    @screen md {
      //clip-path: ellipse(200vw 190vh at -25vw -103vh);
      align-items: center;
      padding-bottom: initial;

      .btn {
        display: inline-flex;
      }
    }

    h1 {
      max-width: 680px;
      font-weight: 700;
      font-size: 48px;
      line-height: 48px;

      @screen lg {
        font-size: 84px;
        line-height: 74px;
        letter-spacing: 0;
      }
    }
  }

  ul#cat-list {
    li {
      display:inline-block;
      padding: 0.5rem 1.5rem;
      padding-bottom: 10px;
      border-bottom: 2px solid transparent;
      cursor: pointer;

      &:first-child {
        padding-left: 0;
      }

      a {
        @extend .underline;

        font-family: $headlineFont;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 14px;
        text-transform: uppercase;
        padding-bottom: 0;
        transition: all 0.45s $defEasing;

        &:hover {
          color: $yellow;
        }
      }
    }

    .cat-selector.active {
      color: $yellow;
    }

  }

  .glide__slide {
    .single-beer-archive {
      min-height: 400px;

      @screen md {
        min-height: 460px;
      }

      @screen lg {
        min-height: 480px;
      }
    }
  }
}

body:not(.home) {
  #main-container {
    padding-top: 77px;
    z-index: 1;
    position: relative;

    @screen sm {
      padding-top: 180px;
    }
  }
}

body.pagebuilder,
body.single-blog-entry {
  #main-container {
    padding-top: 64px;

    @screen md {
      padding-top: 107px;
    }

    section.hero {
      padding-top: 0 !important;
    }

    .hero-container {
      min-height: 40vh;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      @screen md {
        min-height: 26vw;
      }

      h1 {
        max-width: 680px;
      }
    }
  }
}

body.contact {
  h1 {
    max-width: 550px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 32px;
  }

  p {
    margin: 0 auto;
    max-width: 650px;
    text-align: center;
  }

  .profile-card {
    width: 100%;
    height: 100%;
    margin: 3rem auto 0;
    background: linear-gradient(to bottom, transparent 75px, #101820 75px, #101820 100%);
    max-width: 700px;
    position: relative;
    color: white;

    &.active {
      .profile-card__cnt {
        filter: blur(6px);
      }

      .profile-card-message,
      .profile-card__overlay {
        opacity: 1;
        pointer-events: auto;
        transition-delay: .1s;
      }

      .profile-card-form {
        transform: none;
        transition-delay: .1s;
      }
    }

    &__img {
      width: 150px;
      height: 150px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 24px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      z-index: 4;
      border: 4px solid white;

      @media screen and (max-width: 576px) {
        width: 120px;
        height: 120px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    &__content {
      text-align: center;

      h3 {
        color: white;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.3px;
        line-height: 32px;
        text-align: center;
        font-family: $headlineFont;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        @screen lg {
          min-height: 5rem;
        }
      }

      p {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 26px;
        text-align: center;
        margin-bottom: 24px;
      }
    }
  }

  @supports (-webkit-backdrop-filter: blur(1px)) {
    .profile-card {
      margin: initial;
      padding-bottom: 3rem;
    }
  }
}

body.archive-beers {
  h1 {
    color: $copyColor;
    margin-bottom: 32px;
  }

  h2 {
    color: $copyColor;
    font-size: 32px;
    line-height: 32px;
  }

  .single-beer-archive {
    min-height: 110vmin;

    @media screen and (min-width: 500px) and (max-width: 639px) {
      min-height: 90vmin;
    }

    @screen sm {
      min-height: 420px;
    }

    @screen md {
      min-height: 420px;
    }

    @screen lg {
      min-height: 490px;
    }

    @screen 2xl {
      min-height: 490px;
    }
  }

  .beer-grouped {
    min-height: 50vh;
    padding-top: 40px;
    padding-bottom: 80px;
    border-bottom: 2px solid $copyColor;

    &:nth-child(1) {
      padding-top: 40px;

      @screen md {
        padding-top: 0;
      }
    }

    &:last-child {
      border-bottom: 0;
    }

    @screen lg {
      padding-top: 0;
    }
  }
}

body.single-beer {
  h1 {
    color: $copyColor;
    margin-bottom: 24px;

    @screen lg {
      margin-bottom: 40px;
    }
  }

  .beer-features {

    @screen md {
      margin: 33px 0;
    }
  }

  .beer-ingredients {
    @screen md {
      margin-top: 22px;
      font-size: 16px;
    }
  }

  .legend svg {
    circle {
      stroke: #f2a900;
    }

    rect {
      fill: #f2a900;
    }
  }

  p.legend {
    // @extend .h5;
    font-size: 1rem;
    display: flex;
    align-items: center;
    // margin: var(--space-16) 0 var(--space-16);
    cursor: pointer;

    svg {
      margin-left: 4px;
    }
  }

  div.ingredients-popup {
    max-height: 0;
    margin-bottom: 24px;
    overflow: hidden;
    transition: max-height 0.65s ease-in-out;

    &.open {
      max-height: 600px;
    }

    p {
      font-size: 0.85rem;
      font-weight: 400;
      margin-top: 0;
      // margin-bottom: var(--space-32);
    }
  }


  .single-beer-about {
    margin-top: -44%;

    @screen lg {
      margin-top: -27%;
    }

    &__content {
      padding-bottom: 3rem;
      margin-top: 0; //svg correction
      font-weight: 300;

      h2 {
        font-size: 40px;
        line-height: 40px;
        text-transform: lowercase;
        margin-bottom: 32px;
        color: inherit;

        @screen lg {
          line-height: 44px;
        }
      }

      p {
        font-weight: 300;
        position: relative;
        max-height: 140px;
        overflow: hidden;
        transition: max-height 1s ease;

        a {
          @extend .btn, .btn-primary;
          margin-top: 32px;
          background: $copyColor;
        }
      }
    }

    .white-border {
      border: 4px solid #fff;
    }

    .side-image {
      max-width: 100%;
      border-radius: 0 !important;

      @screen md {
        border-radius: 9999px !important;
        max-width: 80%;
        margin: 0 auto;
      }
    }
  }

  .bottle-image {
    z-index: 2000;

    img {
      // margin-left: auto;
      max-height: 440px;
    }

    @screen md {
      img {
        margin-left: auto;
        max-height: 790px;
      }
    }
  }

  .award {
    max-width: 55px;
    height: auto;
    margin-left: -10px;

    @screen md {
      max-width: 100px;
      height: auto;
    }
  }

  .single-beer-archive {
    min-height: 360px;

    @screen md {
      min-height: 420px;
    }

    @screen lg {
      min-height: 480px;
    }
  }
}

body.brewery {
  #main-container > section {
    min-height: auto;
  }

  h1 {
    color: $copyColor;
    margin-bottom: 32px;
  }
}

body.art-of-brewery {
  #main-container > section {
    min-height: auto;
  }

  h1 {
    color: $copyColor;
    margin-bottom: 32px;
  }
}

#newsletter {
  background: #2A343D;

  p label {
    font-size: 14px;
    font-weight: 300;
    color: rgba(255,255,255,0.64);
  }

  label[for="agb"] {
    a {
      text-decoration: underline;
    }
  }

  input[name="email"] {
    background: #4C555C;
    height: 64px;
    width: 100%;
    padding: 24px;
    color: #fff;
  }

  [type="checkbox"] {
    /* Hide the checkbox without
       making it hidden for the
       screen readers */
    position: absolute;
    left: 0;
    opacity: 0.01;
  }

  /* Prepare the label */
  [type="checkbox"] + label {
    position: relative; /* allows to position the custom boxes */
    padding-left: 2.3em; /* room for upcoming boxes */
    font-size: 14px;
    font-weight: 300;
    color: rgba(255,255,255,0.64);
    cursor: pointer;
  }

  /* Box aspect */
  [type="checkbox"]:not(:checked) + label::before,
  [type="checkbox"]:checked + label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    border: 1px solid #aaa;
    background: #FFF;
    border-radius: 0;
    transition: all .275s;
  }

  /* Check aspect */
  [type="checkbox"]:not(:checked) + label::after,
  [type="checkbox"]:checked + label::after {
    content: '\2714';
    speak: never; /* To be sure screen reader won't read "times" */
    position: absolute;
    top: .525em;
    left: .18em;
    font-size: 1.375em;
    color: white;
    line-height: 0;
    transition: all .2s; /* Little transition */
  }

  /* Unchecked aspect */
  [type="checkbox"]:not(:checked) + label::after {
    opacity: 0;
    transform: scale(0) rotate(45deg);
  }

  /* Checked aspect */
  [type="checkbox"]:checked + label::before {
    opacity: 1;
    transform: scale(1) rotate(0);
    background: $yellow;
  }
  [type="checkbox"]:checked + label::after {
    opacity: 1;
    transform: scale(0.8) rotate(0);
    color: #2a343d;
  }

  /* Disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + label::before,
  [type="checkbox"]:disabled:checked + label::before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #e9e9e9;
  }

  /* Disabled checked */
  [type="checkbox"]:disabled:checked + label::after {
    color: #777;
  }

  [type="checkbox"]:disabled + label {
    color: #aaa;
  }
}
