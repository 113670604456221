$yellow: #F2A900;
$yellow-btn: #F6BE00;
$navColor: #101820;
$copyColor: #101820;
$h1-color: #FFFFFF;
$h2-color: #101820;

$lineheight: 20px;
$maxlines: 7;

$headlineFont: "Novecento Slab W03 Wd Bd", serif;
$copyFont: 'HCo Gotham SSm', sans-serif;
$defEasing: cubic-bezier(0.4, 0.0, 0.2, 1);




