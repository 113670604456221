body.archive-blog {
  h1, h2, h3, h4, h5 {
    color: white;
  }
  p {
    color: white;
  }
  .teaser > a {
    justify-content: space-between;
  }
  .teaser > a div p.teaser-link {
    text-shadow: 0 2px 8px rgb(0 0 0 / 50%);
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  div.cat-display {
    background: rgba(0,0,0,0.3);
    text-transform: uppercase;
    padding: 8px 16px;
    visibility: hidden;
  }
}

body.single-blog-entry {
  .p-builder > .pb-textcontent-blog:nth-child(1) .pb-section {
    margin-top: 0;
  }

  .teaser.relpost a {
    justify-content: space-between;

    div p.teaser-link {
      text-shadow: 0 2px 8px rgb(0 0 0 / 50%);
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  div.cat-display {
    background: rgba(0,0,0,0.3);
    text-transform: uppercase;
    padding: 8px 16px;
  }

  #main-container {
    color: #fff;
  }

  .pb_singleentry section.pb-section h3 {
    margin-top: 0px;
  }

  .pb_singleentry section.pb-section:first-child {
    margin-bottom: 0;
  }

  blockquote.eichhof-quote {
    position: relative;
    text-align: left;
    padding: 1.8rem 0.1rem;
    width: 64%;  /* create space for the quotes */
    font-family: "Novecento slab wide W05 Bold";
    text-transform: lowercase;
    color: #EBEBEB;
    margin: 1rem auto 2rem;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 34px;

    &:before,
    &:after{
      font-family: "Novecento slab wide W05 Bold";
      position: absolute;
      font-size: 80px;
      font-weight: bold;
      letter-spacing: 0.77px;
      line-height: 80px;
      color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #F6BE00;
    }

    &:before{
      content: "«";
      top: -12px;
      margin-right: 10px;
      right: 100%;
    }
    &:after{
      content: "»";
      margin-left: -40px;
      left: 100%;
      top: auto;
      bottom: 0;
    }
  }

  p > a {
    @extend .underline;

    font-family: $headlineFont;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 14px;
    text-transform: uppercase;
    padding-bottom: 0;
    transition: all 0.45s $defEasing;

    &:hover {
      color: $yellow;
    }
  }

}

.share-col {
  display: none;

  @screen md {
    position: absolute;
    left: 14vw;
    display: block;
  }
}

.share-cp {
  > div {
    min-height: 64px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    padding: 8px 16px;
    border: 2px solid #2A343D;
  }

  a {
    &:hover {
      filter: brightness(0) saturate(100%) invert(68%) sepia(91%) saturate(2788%) hue-rotate(8deg) brightness(104%) contrast(102%);
    }
  }
}

.author_avatar {
  width: 64px;
  border-radius: 100% !important;
  height: 64px;
  object-fit: cover;
  display: inline-block;

  + div {
    padding-top: 8px;
    display: inline-block;
    vertical-align: top;
  }
}

.author_meta {
  margin-left: 16px;

  h4 {
    text-transform: uppercase;
    font-family: "Novecento slab wide W05 Bold", serif;
    font-weight: bold;
  }

  p {
    font-size: 14px;
  }
}

.intro-single-article p {
  margin-top: 24px !important;
  font-weight: bold !important;
  font-size: 20px !important;
  line-height: 30px !important;
}

.range {
  display: block;
  margin: 130px auto 2rem;
  width: 320px;
  position: relative;
}

.range__slider {
  width: 100%;
}

.range-wrapper {
  width: 320px;
  position: relative;
}

.form-group {
  display: block;
}

.form-group label {
  text-transform: uppercase;
  font-size: .7rem;
  color: #222;
  margin-bottom: 5px;
}

.form-group span {
  font-size: 2rem;
  font-weight: 600;
  color: #3c3b3b;
}

.range__slider label {
  margin-bottom: 10px;
}

.range__slider [type="range"] {
  width: 100%;
  -webkit-appearance: none;
  height: 13px;
  border-radius: 6px;
  background: #f1f1f1;
  outline: none;
  padding: 0;
  margin: 0;
}

/* custom thumb */
.range__slider [type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $yellow;
  border: #fff 2px solid;
  cursor: pointer;
  -webkit-transition: background .15s ease-in-out;
  transition: background .15s ease-in-out;
}

.range__slider [type="range"]::-webkit-slider-thumb:hover {
  background: $yellow;
  border: #fff 2px solid;
}

.range__slider [type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $yellow;
  border: #fff 2px solid;
  cursor: pointer;
  -webkit-transition: background .15s ease-in-out;
  transition: background .15s ease-in-out;
}

.range__slider [type="range"]::-moz-range-thumb:hover {
  background: $yellow;
}

/* remove border */
input::-moz-focus-inner, input::-moz-focus-outer {
  border: 0;
}

#lottie-beer {
  width: 100px;
  margin: 0 auto;
  text-align: center;
}

.lottie-beer {
  position: absolute;
  pointer-events: none;
  bottom: 30px;
}
