.side-nav {
  ul {
    li {
      margin: 6px 0;
      max-width: 280px;

      a.site-nav {
        opacity: 0.54;
        display: block;
        color: $copyColor;
        font-family: $headlineFont;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.2px;
        line-height: 20px;
        padding: 24px 46px 24px 120px;
        transition: all 0.3s $defEasing;

        &:hover,
        &.active {
          opacity: 1;
          background: $yellow;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.side-nav-content {
  li {
    margin: 0.25rem 0;
    max-width: 280px;
    background: transparent;

    a.site-nav {
      opacity: 0.54;
      display: block;
      color: $copyColor;
      font-family: $headlineFont;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.2px;
      line-height: 20px;
      padding: 12px 60px 12px 10px;
      text-transform: uppercase;
      transition: all 0.3s $defEasing;

      &:hover,
      &.active {
        opacity: 1;
        color: $copyColor;
        background: $yellow;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &.simple {
    a.site-nav {
      opacity: 0.64;
      display: block;
      color: $copyColor;
      font-family: $headlineFont;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
      padding: 4px 23px 4px 30px;
      text-transform: uppercase;
      transition: all 0.3s $defEasing;

      &:hover,
      &.active {
        opacity: 1;
        color: $yellow;
        background: transparent;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

body.inverted {
  .side-nav-content {
    li {
      margin: 0.25rem 0;
      max-width: 280px;
      background: transparent;

      a.site-nav {
        opacity: 0.54;
        display: block;
        color: #fff;
        font-family: $headlineFont;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.2px;
        line-height: 20px;
        padding: 12px 60px 12px 10px;
        text-transform: uppercase;
        transition: all 0.3s $defEasing;

        &:hover,
        &.active {
          opacity: 1;
          color: $copyColor;
          background: $yellow;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }

    &.simple {
      a.site-nav {
        opacity: 0.64;
        display: block;
        color: #fff;
        font-family: $headlineFont;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        padding: 4px 23px 4px 30px;
        text-transform: uppercase;
        transition: all 0.3s $defEasing;

        &:hover,
        &.active {
          opacity: 1;
          color: $yellow;
          background: transparent;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

#side-nav {
  position: sticky;
  position: -webkit-sticky;
  width: 100px;
  height: 500px;
  top: 130px;
  margin-top: 130px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
