footer {
  position: relative;
  margin-top: 140px;
  z-index: 1;

  @screen lg {
    margin-top: 240px;
  }

  div.logo {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 50%;
    transform: translate(-50%, -75%);
    width: 110px;

    @screen md {
      width: 180px;
    }
  }

  .footer-nav {
    background: $yellow-btn;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 64px;
    position: relative;
    z-index: 2000;

    a {
      font-family: $headlineFont;
      text-transform: uppercase;
      padding: 0.5rem 1.5rem;
      font-size: 20px;
      opacity: 1;
      transition: all 0.3s $defEasing;

      &:hover {
        opacity: 0.64;
      }

      @screen md {
        font-size: 16px;
      }
    }

    nav {
      justify-content: center;
      align-items: center;
      text-align: center;

      @screen md {
        text-align: initial;
      }
    }

    @screen lg {
      a {
        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  .footer-bottom-nav {
    background: $yellow;
    min-height: 64px;
    padding-top: 7px;
    position: relative;
    z-index: 2021;

    .footer-i-nav {
      order: 3;

      @screen md {
        order: 1;
      }
    }

    .footer-i-nav-1 {
      order: 2;

      @screen md {
        order: 2;
      }
    }

    .footer-i-nav-2 {
      order: 1;

      @screen md {
        order: 3;
      }
    }

    nav {
      a {
        font-family: $copyFont;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 14px;
        margin-right: 15px;
        text-transform: uppercase;
        opacity: 1;
        transition: all 0.3s $defEasing;

        &:hover {
          opacity: 0.64;
        }
      }
    }

    .social-icons {
      ul {
        li {
          display: inline-block;
          padding: 15px 30px;

          a {
            font-family: $copyFont;
            text-transform: uppercase;

            svg {
              opacity: 1;
              transition: all 0.3s $defEasing;
            }

            &:hover {
              svg {
                opacity: 0.64;
              }
            }

          }
        }
      }
    }
  }
}
