.teaser {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  min-height: calc(100vw / 1.1);

  &.relpost {
    h1,
    h2,
    h3 {
      margin-bottom: 0.5rem;
    }

    p {
      margin-bottom: 0.75rem;
    }
  }

  @screen md {
    min-height: calc(100vw / 3);
  }

  @screen lg {
    min-height: calc(100vw / 3);
  }

  @screen xl {
    min-height: calc(100vw / 3);
  }

  @screen 2xl {
    min-height: calc(100vw / 3.5);
    max-height: 660px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(4.74deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s $defEasing;
    background: rgba(0, 0, 0, 0.32);
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  > a {
    color: white;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 2rem;
    height: 100%;
    flex-direction: column;
    z-index: 3;
    width: 100%;

    h1,
    h3 {
      color: white;
      font-family: $headlineFont;
      text-transform: lowercase;
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 0.4px;
      line-height: 36px;
      text-shadow: 0 2px 8px rgba(0,0,0,0.5);
    }

    h1 {
      text-transform: uppercase;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 22px;
      text-shadow: 0 2px 8px rgba(0,0,0,0.5);
    }

    > p.teaser-link {
      text-shadow: 0 2px 8px rgba(0,0,0,0.5);
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}
