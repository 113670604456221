body.no-scroll {
  overflow: hidden;
}

.overlay-verify{
  background: $copyColor;
  position:fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: none;
  opacity: 0;
  transition: opacity 0.65s $defEasing;
  pointer-events: none;

  &.active {
    opacity: 1;
    display: block;
  }
}

.box{
  background: #fff;
  position: absolute;
  left: 16px;
  right: 16px;
  top: 16px;
  bottom: 0;
  margin: 0 auto;
  z-index: 99999;
  width: 93%;
  max-width: 1400px;
  display: none;

  @screen md {
    top: 20%;
  }

  &.active {
    display: table;
  }

  .box-left, .box-right{
    width: 100%;
    position: relative;
    text-align: center;
    padding: 5%;

    @media (min-width: 54em){
      display:table-cell;
      vertical-align:middle;
      width: 50%;
    }

    p{
      //padding: 5%;
      position: relative;
      z-index: 3;
    }
  }
  .box-left{
    background:url(http://uiclients.com/thinc/assets/images/block-bg-2.jpg) 50% 50%;
    background-size: cover;
    color: #fff;
    height: 30vh;

    img{
      position: relative;
      z-index: 4;
      width: 9em;
    }

    &:after{
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0,0,0,0.4);
    }
  }

  .box-right{
    text-align: center;

    h3 {
      color: $copyColor;
      text-transform: uppercase;
      letter-spacing: 0.07em;
      border-bottom: 1px solid #eee;
      padding-bottom: 1em;
      margin: 0 auto;
      text-align: left;

      @screen md {
        text-align: left;
      }
    }

    p{
      color: #aaa;
    }

    small{
      color: #ccc;
    }
    .btn{
      font-weight: 600;
      letter-spacing: 0.2em;
      padding: 0.9em 1em 0.7em;
      margin: 1em auto;
      display: block;
    }
  }
}

/* purgecss start ignore */
#CybotCookiebotDialog {
  #CybotCookiebotDialogBody {
    max-width: 1440px;
  }

  #CybotCookiebotDialogBodyContentTitle {
    font-family:"Novecento slab wide W05 Bold" !important;
  }

  #CybotCookiebotDialog div {
    font-family: 'HCo Gotham SSm' !important;
  }

  #CybotCookiebotDialogBodyButtonDecline {
    padding: 1rem 2rem;
    min-height: 64px;
    justify-content: center;
    align-items: center;
    font-family: $headlineFont !important;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 14px;
    text-align: center;
    display: flex;
    background: $yellow-btn !important;
    color: $copyColor !important;
    border: 2px solid $yellow-btn !important;

    &:hover {
      background: transparent !important;
      color: $yellow-btn !important;
      border: 2px solid $yellow-btn !important;
    }
  }

  #CybotCookiebotDialogBodyButtonAccept {
    padding: 1rem 2rem;
    min-height: 64px;
    justify-content: center;
    align-items: center;
    font-family: $headlineFont !important;
    font-size: 14px !important;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 14px;
    text-align: center;
    display: flex;
    background: transparent;
    color: $yellow-btn !important;
    border: 2px solid $yellow-btn !important;

    &:hover {
      background: $yellow-btn !important;
      color: $copyColor !important;
      border: 2px solid $yellow-btn !important;
    }
  }
}
/* purgecss end ignore */
